import React, { Component } from 'react';
import { UncontrolledCollapse, Button } from 'reactstrap';
import Route from './Route';

export class Bus extends Component {

    constructor(props) {
        super(props);
        this.state = {
            routes: [],
            loading: true,
        };
    }

    componentDidMount() {
        this.populateBusData();
    }

    static renderRoutes(routes) {
        return (
            <div>
                <h4>バスルート情報</h4>
                <div className="d-flex flex-column">
                    {routes.map((item, index) => Bus.renderRoute(item, index))}
                </div>
            </div>
        );
    }

    static renderRoute(route, index) {
        const togglerId = `toggler-${index}`;
        return (
            <div key={index}>
                <Button color="info" id={togglerId} className="mb-4">
                    {route.routeName}
                </Button>
                <UncontrolledCollapse toggler={`#${togglerId}`}>
                    <Route route={route} />
                </UncontrolledCollapse>
            </div>
        );
    }

    render() {
        const { loading, routes } = this.state;
        const contents = loading
            ? <p><em>読み込み中...</em></p>
            : Bus.renderRoutes(routes);

        return (
            <div>
                {contents}
            </div>
        );
    }

    async populateBusData() {
        try {
            const response = await fetch('busreservation/getroutestops');
            const data = await response.json();
            this.setState({ routes: data, loading: false });
        } catch (error) {
            console.error('Failed to fetch bus data', error);
            this.setState({ loading: false });
        }
    }
}
