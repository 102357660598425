import React, { Component } from 'react';
// Assuming you want to use some components from reactstrap
import { Card, CardBody } from 'reactstrap';

class Route extends Component {
    render() {
        const { route } = this.props;

        return (
            <Card className="block">
                <CardBody>
                    <h5>{route.routeName}</h5>
                    <ol>
                        {route.stops.map(stop => (
                            <li key={stop.id}>{stop.stopName} {stop.memo}</li>
                        ))}
                    </ol>
                </CardBody>
            </Card>
        );
    }
}

export default Route;
